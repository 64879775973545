
            
            import { runInjectionScript, swapDocument } from "@clerk/astro/internal";
            import { navigate, transitionEnabledOnThisPage } from "astro:transitions/client";

            if (transitionEnabledOnThisPage()) {
              document.addEventListener('astro:before-swap', (e) => {
                const clerkComponents = document.querySelector('#clerk-components');
                // Keep the div element added by Clerk
                if (clerkComponents) {
                  const clonedEl = clerkComponents.cloneNode(true);
                  e.newDocument.body.appendChild(clonedEl);
                }

                e.swap = () => swapDocument(e.newDocument);
              });

              document.addEventListener('astro:page-load', async (e) => {
                await runInjectionScript({
                  ...{"sdkMetadata":{"version":"1.4.7","name":"@clerk/astro","environment":"production"}},
                  routerPush: navigate,
                  routerReplace: (url) => navigate(url, { history: 'replace' }),
                });
              })
            } else {
              await runInjectionScript({"sdkMetadata":{"version":"1.4.7","name":"@clerk/astro","environment":"production"}});
            }
import Alpine from 'alpinejs';
import { setup } from 'virtual:@astrojs/alpinejs/entrypoint';
setup(Alpine);
window.Alpine = Alpine;
Alpine.start();
import "/app/sentry.client.config.js";